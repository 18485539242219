// eslint-disable-next-line no-redeclare,no-unused-vars
function administrateObject(param) {
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;
  view.header.find('.admin-info').remove();
  var adminInfo = $(
    '<span class="admin-info px-2">' +
      param.object +
      (param.objectSub ? ':' + param.objectSub : '') +
      '</span>'
  ).appendTo(view.header.find('.view-title'));

  // general settings
  if (!view.generalSettings || param.updating) {
    contextMenu(
      adminInfo,
      {options: 'Tabellen- und Dialogoptionen'},
      function (type) {
        if (type == 'options') {
          var pm = {
            type: 'options',
            object: 'adminParameter',
            top: param.view,
            icon: 'cog',
          };
          var objectInfo = object + (objectSub ? ':' + objectSub : '');
          pm.title = 'Tabellen- und Dialogoptionen: ' + objectInfo;
          pm.id = object;
          [pm.row, pm.settings] = optionsField({
            row: parameter[object],
            settings: fields.adminParameter,
            object: object,
            objectSub: param.objectSub,
            parameter: true,
          });
          pm.save = function (value, element, option) {
            if (pm.settings[option].field == 'checkbox' && !value) value = '';
            else if (
              checkSettings('table', pm.settings[option]) &&
              isArray(value)
            ) {
              var val = {};
              $.each(value, function (i, row) {
                //				if (key == 'where' && row.value && row.value.indexOf(',') > -1) row.value = row.value.split(',');
                if (!row.value) row.value = '';
                else if (
                  row.value.slice(0, 1) == '{' &&
                  row.value.slice(-1) == '}'
                )
                  try {
                    row.value = JSON.parse(row.value);
                  } catch {
                    showInfo(row.value + ' fehlerhaft', true);
                    view.error = true;
                  }
                val[row.key] = row.value;
              });
              value = val;
            } else if (pm.settings[option].array && value && isString(value)) {
              if (value.indexOf('|') > 0) value = value.split('|');
              else value = value.split(',');
            } else if (strpos(value, '{') === 0 && value.slice(-1) == '}')
              try {
                value = JSON.parse(value);
              } catch {
                // FIXME row is not defined
                // eslint-disable-next-line no-undef
                showInfo(row.value + ' fehlerhaft', true);
                view.error = true;
              }
            if (view.error) {
              delete view.error;
              return;
            }

            if (param.objectSub && !inArray(option, globalOptions))
              option += ucfirst(param.objectSub);
            saveAdminSettings({
              type: 'option',
              object: object,
              key: option,
              value: value,
            });
            if (!parameter[object]) parameter[object] = {};
            parameter[object][option] = value;
            if (view.updateTimer) clearTimeout(view.updateTimer);
            view.updateTimer = setTimeout(function () {
              if (param.view.updateData) param.view.updateData();
            }, 1000);
          };
          var view = buildPopup(pm);
          buildForm(view.body, pm, parameter.adminParameter.form);

          // var settings = parameter[object];
          // var title = 'Optionen Tabellen- und Dialogoptionen';
          // var popup = buildPopup({title:title,type:'options',top:param.view});
          // var box = $('<div class="box"></div>').prependTo(popup);
          //
          // $.each(['label','short','tableFields','index','excelFields','sort','where','read','hidden','save','button','full','mailer','upload','warningRed','warningYellow','warningGreen','warningOr','downloader','autoMail','autoImport','webhook','resource','maxAmount','searchVisible'],function(i,key){
          // 	var option = key; if (objectSub) key += ucfirst(objectSub);
          // 	var value = settings[key];
          // 	optionField({object:object,key:option,keySave:key,value:value,settings:settings,box:box,popup:popup,afterSave:function(value){
          // 		parameter[object][option] = value;
          // 		param.view.update();
          // 	}});
          // });
        }
      }
    );
    view.generalSettings = true;
  }

  // table settings
  // contextMenu(view.find('thead th'), {columnsSave: 'Tabellenspalten in Settings übernehmen', sortSave: 'Sortierung in Settings übernehmen'}, function (type, target) {
  // 	if (type == 'columnsSave') {
  // 		var key = 'tableFields';
  // 		if (objectSub) key += ucfirst(objectSub);
  // 		saveAdminSettings({type: 'option', object: object, key: key, value: parameter[object][key]});
  // 	} else if (type == 'sortSave') {
  // 		var key = 'sort';
  // 		if (objectSub) key += ucfirst(objectSub);
  // 		saveAdminSettings({type: 'option', object: object, key: key, value: view.table.sort});
  // 	}
  // });

  // add buttons
  if (view.table) {
    if (user.role == 'superadmin')
      $(
        '<button type="button" class="btn btn-light"><i class="far fa-long-arrow-right fa-fw"></i></button>'
      )
        .appendTo(view.buttonsLeft)
        .addTooltip('Daten in anderen Mandant schieben')
        .on(click, function () {
          var selected = view.table.selected();
          if (!selected.length) {
            showInfo(info.pickedEmpty, 'warning');
            return;
          }
          var client = prompt('client');
          if (!client) return;
          ajax({
            object: object,
            view: view,
            send: {
              func: 'saveParallel',
              ids: selected,
              client: client,
            },
          });
        });
    if (test && !develop && view.table.selectRow && param.overview) {
      $(
        '<button type="button" class="btn btn-light btn-fix"><i class="far fa-level-up fa-fw"></i></button>'
      )
        .appendTo(view.buttonsLeft)
        .addTooltip('Daten ins Livesystem überspielen')
        .on(click, function () {
          var selected = view.table.selected();
          if (!selected.length) {
            showInfo(info.pickedEmpty, 'warning');
            return;
          }
          ajax({
            object: object,
            view: view,
            send: {func: 'saveLive', ids: selected},
          });
        });
    }
  }
}
